import React from 'react'
import { graphql } from 'gatsby'
import { ArticleLayout } from "../layouts/"

export const query = graphql`
    query CaseTemplateQuery($id: String!) {
        model: sanityCase(id: { eq: $id }) {
            ...CaseTemplate
        }
    }
`

const ExampleTemplate = (props) => {

    return (
        <ArticleLayout {...props} />
    )

}

export default ExampleTemplate
